import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import MainLayout from '../components/layouts/MainLayout'
import SEO from '../components/seo'

import './404.css'

function NotFoundPage() {
  const data = useStaticQuery(graphql`
    {
      leeloo: file(relativePath: { eq: "lol/leeloo.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      sparrow: file(relativePath: { eq: "lol/sparrow.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <MainLayout>
      <SEO title="404: Not found" />
      <h1 className="lost-title">Il n&apos;y a rien ici...</h1>
      <p className="paragraph">
        On dirait bien que tu t&apos;es perdu(e) !<br /> Mais comme on est
        gentils, voici des photos de chats !
      </p>
      <GatsbyImage
        image={data.leeloo.childImageSharp.gatsbyImageData}
        className="img-cat"
        alt="Une photo de notre chat Leeloo"
      />
      <GatsbyImage
        image={data.sparrow.childImageSharp.gatsbyImageData}
        className="img-cat"
        alt="Une photo de notre chat Sparrow"
      />
    </MainLayout>
  )
}

export default NotFoundPage
